import {
  ExperienceForm,
  ExperienceFormSchema,
  ExperienceSearchParams,
} from '@reward-platform/ancillaries-schemas/experience'
import { useClickAway } from '@reward-platform/lift/hooks/useClickAway'
import { formatValidationMessage, getValidationErrorMessage } from '@reward-platform/utils'
import format from 'date-fns/format'
import { useRouter } from 'next/router'
import { stringify } from 'qs'
import { ForwardedRef, useCallback, useMemo, useRef, useState } from 'react'
import { useForm, UseFormReturn } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useQuery } from 'react-query'
import { ZodIssueCode } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useNotification } from '~/components/shared/Notifications'
import useDebounce from '~/hooks/useDebounce/useDebounce'
import { getLocations } from '~/services/locationService'
import { ONE_DAY } from '~/utils/datetime'
import { pushEventToDataLayer } from '~/utils/googleTagManager'
import { usePartner } from '~/context/partner'
import { LocationDataTransferObjects } from '@reward-platform/ancillaries-schemas/location'

const SEARCH_TYPE = 'EXPERIENCE'

export type LocationExperienceOptionData =
  | {
      vendorBrandId?: string
      type: string
      id: string
      fullName: string
      name: string
      countryCode: string
    }[]
  | {
      emailAddress?: string
      afterHoursIndicator?: string | boolean
      telephoneNumber?: string
      location?: { latitude?: string; longitude?: string }[]
      openingTimes?: { day: string; hours: string[] }[]
      type: string
      id: string
      fullName: string
      name: string
      countryCode: string
      address: string
    }[]

/**
 * Set up and manage state for the Destination autocomplete field
 */
export function useDestinationState(
  form: UseFormReturn<ExperienceForm>,
  defaultState: ExperienceForm['destination']
) {
  const { code: partner } = usePartner()
  const fieldName: keyof ExperienceSearchParams = 'destination'
  const { addNotification } = useNotification()
  const [searchString, setSearchString] = useState<string>(defaultState?.fullName || '')
  const fieldValue = form.getValues(fieldName)

  const debouncedSearch = useDebounce(searchString, 500)

  const querySameAsFullName = searchString === fieldValue.fullName
  const canSearch =
    debouncedSearch !== '' && debouncedSearch === searchString && !querySameAsFullName

  const { data: locations, isLoading } = useQuery(
    ['locations', partner, debouncedSearch],
    () => getLocations(partner, SEARCH_TYPE, debouncedSearch),
    {
      enabled: canSearch,
      keepPreviousData: true,
      onError: (e: Error) => addNotification(e.message),
    }
  )

  const noResultsFound = locations?.length === 0 && !isLoading && canSearch

  const onInputFocus = useCallback(
    // Use shorter name as search query when re-focusing input field, as fullName will usually not yield results. Could be removed if search is enhanced in future.
    (e: { target: { select: () => void } }, openMenu: () => void) => {
      e.target.select()
      setSearchString(fieldValue.fullName)
      openMenu()
    },
    [fieldValue.fullName]
  )

  const handleAutocompleteChange = useCallback(
    (value: string) => {
      setSearchString(value)
      if (value.length === 0) {
        form.setValue(fieldName, { id: '', type: '', fullName: '' }, { shouldDirty: true })
      }
    },
    [form]
  )

  const handleSuggestionClick = useCallback(
    (key: string) => {
      const location = (locations as LocationDataTransferObjects).find(({ id }) => id === key)
      if (location) {
        setSearchString(location.fullName)
        form.clearErrors(fieldName)
        form.setValue(fieldName, location, { shouldValidate: true })
      }
    },
    [locations, form, fieldName]
  )

  return {
    isLoading,
    locations: isLoading || !canSearch || !locations ? [] : locations,
    ...fieldValue,
    searchString,
    noResultsFound,
    onInputFocus,
    handleAutocompleteChange,
    handleSuggestionClick,
  }
}

/**
 * Set up and manage the state for the date range fields
 */
export function useDateRangeState(
  form: UseFormReturn<ExperienceForm>,
  [defaultStartDate, defaultEndDate]: [Date, Date | null]
) {
  const [startDate, setStartDate] = useState(defaultStartDate)
  const [endDate, setEndDate] = useState<Date | null>(defaultEndDate)
  const [showCalendar, setShowCalendar] = useState(false)
  const calendarRef = useRef<HTMLInputElement | null>(null)

  useClickAway(calendarRef, showCalendar, () => setShowCalendar(false))

  const handleDateChange = useCallback(
    (dates: [Date | null, Date | null]) => {
      if (!dates || !Array.isArray(dates)) {
        return
      }
      const [start, end] = dates
      if (!start) {
        return
      }

      setStartDate(start)
      form.setValue('startDateTime', start)

      if (end?.getTime() === start.getTime()) {
        end.setDate(start.getDate() + 1)
      }

      if (end === null) {
        form.resetField('endDateTime')
        form.setValue('endDateTime', null)
      }

      setEndDate(end)
      if (end) {
        form.setValue('endDateTime', end, { shouldValidate: true })
      }

      if (end != null) {
        setShowCalendar(false)
      }
    },
    [form]
  )

  const minDate = useMemo(() => {
    const from = new Date()
    from.setDate(from.getDate() + 3)
    return from
  }, [])

  const maxDate = useMemo(() => new Date(startDate.getTime() + 365 * ONE_DAY), [startDate])

  return {
    startDate,
    endDate,
    minDate,
    maxDate,
    showCalendar,
    setShowCalendar,
    calendarRef,
    handleDateChange,
  }
}

/**
 * Automatically converts the zod validation errors to a translated human readable error message
 */
function useExperienceSearchErrors(errors: UseFormReturn<ExperienceForm>['formState']['errors']) {
  const intl = useIntl()
  const { notAValid, mustBeSelected } = formatValidationMessage(intl)

  const t = useMemo(
    () => ({
      searchString: intl.formatMessage({ id: 'search', defaultMessage: 'search string' }),
      searchType: intl.formatMessage({ id: 'searchType', defaultMessage: 'search type' }),
      startDateTime: intl.formatMessage({ id: 'startDateTime', defaultMessage: 'start date' }),
      endDateTime: intl.formatMessage({ id: 'endDateTime', defaultMessage: 'end date' }),
      destination: intl.formatMessage({ id: 'destination', defaultMessage: 'Destination' }),
    }),
    [intl]
  )

  const errorMap = useMemo(
    () => ({
      searchString: { [ZodIssueCode.custom]: notAValid(t.searchString) },
      startDateTime: { [ZodIssueCode.custom]: notAValid(t.startDateTime) },
      endDateTime: { [ZodIssueCode.invalid_date]: notAValid(t.endDateTime) },
      destination: { [ZodIssueCode.custom]: mustBeSelected(t.destination) },
    }),
    [t, notAValid, mustBeSelected]
  )

  // Returns standard error regardless of specific nested errors
  const getDestinationError = useCallback(
    () => errors?.destination && errorMap.destination[ZodIssueCode.custom],
    [errors, errorMap]
  )

  const getFieldError = useCallback(
    (field: keyof typeof errorMap) =>
      errors?.[field] &&
      getValidationErrorMessage({
        errorMap,
        errors,
        field,
        intl,
      }),
    [errors, intl, errorMap]
  )

  return {
    getFieldError,
    getDestinationError,
  }
}

/**
 * Sets up the react-hook-form and creates the submit handler and error translations
 * @param defaultValues The default values for the form state
 */
export function useExperienceSearchForm(
  defaultValues: ExperienceForm,
  toggleRef?: ForwardedRef<HTMLButtonElement>
) {
  const { push } = useRouter()
  const form = useForm<ExperienceForm>({
    defaultValues,
    resolver: zodResolver(ExperienceFormSchema),
  })
  const { getValues } = form
  const errors = useExperienceSearchErrors(form.formState.errors)

  const handleFormSubmit = useCallback(() => {
    const { startDateTime, endDateTime, searchString, ...values } = getValues()

    if (!endDateTime) {
      form.setError('endDateTime', { type: ZodIssueCode.invalid_date })
      return
    }

    const searchData = {
      ...values,
      searchType: SEARCH_TYPE,
      dates: {
        startDateTime: format(new Date(startDateTime), "y-MM-dd'T'HH:mm:ss"),
        endDateTime: endDateTime && format(new Date(endDateTime), "y-MM-dd'T'HH:mm:ss"),
      },
      page: 1, // this is always a new search
    }

    if (toggleRef && typeof toggleRef !== 'function') {
      toggleRef?.current?.click()
    }

    pushEventToDataLayer('searchHotel', {
      destination: searchData.destination.fullName,
      dateCheckIn: format(new Date(searchData.dates.startDateTime), 'dd-MM-y'),
      dateCheckOut: format(new Date(searchData.dates.endDateTime), 'dd-MM-y'),
    })

    push({
      pathname: '/experiences/results',
      query: stringify(searchData),
    })
  }, [form, getValues, push, toggleRef])

  return {
    form,
    handleFormSubmit,
    errors,
  }
}
